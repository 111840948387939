
$spot-color-basic-black: rgb(0, 0, 0)
$spot-color-basic-gray-1: rgb(51, 51, 51)
$spot-color-basic-gray-2: rgb(85, 85, 85)
$spot-color-basic-gray-3: rgb(135, 135, 135)
$spot-color-basic-gray-4: rgb(204, 204, 204)
$spot-color-basic-gray-5: rgb(224, 224, 224)
$spot-color-basic-gray-6: rgb(243, 243, 243)
$spot-color-basic-white: rgb(255, 255, 255)
$spot-color-main: rgb(26, 103, 163)
$spot-color-main-dark: rgb(3, 69, 121)
$spot-color-main-light: rgb(209, 229, 245)
$spot-color-accent: rgb(48, 135, 32)
$spot-color-accent-dark: rgb(0, 117, 40)
$spot-color-accent-light: rgb(191, 238, 182)
$spot-color-danger: rgb(208, 17, 0)
$spot-color-danger-dark: rgb(125, 0, 13)
$spot-color-danger-light: rgb(244, 171, 169)
$spot-color-indication-attention: rgb(0, 163, 255)
$spot-color-indication-flagged: rgb(28, 182, 192)
$spot-color-indication-current-date: rgb(255, 255, 225)
$spot-color-feedback-error-dark: rgb(202, 63, 63)
$spot-color-feedback-error-light: rgb(254, 218, 218)
$spot-color-feedback-warning-dark: rgb(239, 158, 86)
$spot-color-feedback-warning-light: rgb(255, 230, 198)
$spot-color-feedback-success-dark: rgb(53, 197, 63)
$spot-color-feedback-success-light: rgb(216, 253, 209)
$spot-color-feedback-info-dark: rgb(143, 192, 219)
$spot-color-feedback-info-light: rgb(227, 245, 255)
$spot-spacing-5: 5rem
$spot-spacing-6: 6rem
$spot-spacing-1-75: 1.75rem
$spot-spacing-7: 7rem
$spot-spacing-0-75: 0.75rem
$spot-spacing-8: 8rem
$spot-spacing-9: 9rem
$spot-spacing-2-25: 2.25rem
$spot-spacing-1-25: 1.25rem
$spot-spacing-0-25: 0.25rem
$spot-spacing-0-5: 0.5rem
$spot-spacing-1-5: 1.5rem
$spot-spacing-2-5: 2.5rem
$spot-spacing-10: 10rem
$spot-spacing-3-5: 3.5rem
$spot-spacing-4-5: 4.5rem
$spot-spacing-5-5: 5.5rem
$spot-spacing-1: 1rem
$spot-spacing-2: 2rem
$spot-spacing-3: 3rem
$spot-spacing-4: 4rem
$spot-shadow-light-low: 2px 2px 5px rgba(0, 0, 0, 0.15)
$spot-shadow-light-mid: 4px 4px 10px rgba(0, 0, 0, 0.15)
$spot-shadow-light-high: 6px 6px 20px rgba(0, 0, 0, 0.15)
$spot-shadow-hard-low: 2px 2px 5px rgba(0, 0, 0, 0.25)
$spot-shadow-hard-mid: 4px 4px 10px rgba(0, 0, 0, 0.25)
$spot-shadow-hard-high: 6px 6px 20px rgba(0, 0, 0, 0.25)
$spot-mq-landscape: '(orientation: landscape)'
$spot-mq-drop-modal-in-context: '(min-width: 1000px) and (min-height: 500px)'
$spot-mq-action-bar-change: '(min-width: 768px)'
