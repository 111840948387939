.spot-container
  display: flex
  flex-direction: column

  > .spot-header-big,
  > .spot-header-small,
  > .spot-subheader-big,
  > .spot-subheader-small,
  > .spot-body-big,
  > .spot-body-small,
  > .spot-caption,
  > .spot-text-field
    margin: 0 $spot-spacing-1
    margin-top: $spot-spacing-1

    &:last-child
      margin-bottom: $spot-spacing-1

  > .spot-list
    padding: $spot-spacing-1 0

    &:first-child
      padding-top: $spot-spacing-1

    &:last-child
      padding-bottom: $spot-spacing-1
