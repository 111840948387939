.op-checkbox-field
  line-height: 1.6rem

  &--label-wrap
    display: grid
    grid-template-columns: auto 1fr
    grid-template-rows: auto auto

  &--input
    grid-column-start: 1
    grid-column-end: 2
    grid-row-start: 1
    grid-row-end: 3
    align-self: start
    padding-right: 1rem
    margin-bottom: 0
    margin-top: 0.1rem

  &--label
    grid-column-start: 2
    grid-column-end: 2
    grid-row-start: 1
    grid-row-end: 2
    margin-bottom: 0

  &--description
    grid-column-start: 2
    grid-column-end: 2
    grid-row-start: 2
    grid-row-end: 3
    margin-bottom: 0

  &--label,
  &--description
    white-space: pre-line
    word-break: break-word
    word-wrap: break-word
