//-- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2022 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

/***** Diff ****

img.diff, div.diff, table.diff, blockquote.diff, address.diff, h1.diff, h2.diff, h3.diff, h4.diff, h5.diff, h6.diff, hr.diff, p.diff, pre.diff, table.diff, ul.diff, ol.diff
  padding: 3px
  margin: 3px

del
  &.diffmod, &.diffdel
    background: #fcc

ins
  &.diffmod, &.diffins
    background: #cfc

.text-diff
  padding: 1em
  background-color: #f6f6f6
  color: #505050
  border: 1px solid #e4e4e4
  line-height: normal
