.spot-icon 
  display: inline-flex
  justify-content: center
  align-items: center

  &::before 
    font-family: "openproject-icon-font"
    font-style: normal
    font-weight: normal
    font-variant: normal
    font-size: inherit
    text-transform: none
    text-decoration: none
    display: block
    speak: none
    line-height: 1
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale


@mixin spot-icon-accessibility 
  content: "\f101"

.spot-icon_accessibility:before 
  content: "\f101"


@mixin spot-icon-accountable 
  content: "\f102"

.spot-icon_accountable:before 
  content: "\f102"


@mixin spot-icon-add 
  content: "\f103"

.spot-icon_add:before 
  content: "\f103"


@mixin spot-icon-align-center 
  content: "\f104"

.spot-icon_align-center:before 
  content: "\f104"


@mixin spot-icon-align-justify 
  content: "\f105"

.spot-icon_align-justify:before 
  content: "\f105"


@mixin spot-icon-align-left 
  content: "\f106"

.spot-icon_align-left:before 
  content: "\f106"


@mixin spot-icon-align-right 
  content: "\f107"

.spot-icon_align-right:before 
  content: "\f107"


@mixin spot-icon-arrow-down1 
  content: "\f108"

.spot-icon_arrow-down1:before 
  content: "\f108"


@mixin spot-icon-arrow-down2 
  content: "\f109"

.spot-icon_arrow-down2:before 
  content: "\f109"


@mixin spot-icon-arrow-left-right 
  content: "\f10a"

.spot-icon_arrow-left-right:before 
  content: "\f10a"


@mixin spot-icon-arrow-left1 
  content: "\f10b"

.spot-icon_arrow-left1:before 
  content: "\f10b"


@mixin spot-icon-arrow-left2 
  content: "\f10c"

.spot-icon_arrow-left2:before 
  content: "\f10c"


@mixin spot-icon-arrow-left3 
  content: "\f10d"

.spot-icon_arrow-left3:before 
  content: "\f10d"


@mixin spot-icon-arrow-left4 
  content: "\f10e"

.spot-icon_arrow-left4:before 
  content: "\f10e"


@mixin spot-icon-arrow-right2 
  content: "\f10f"

.spot-icon_arrow-right2:before 
  content: "\f10f"


@mixin spot-icon-arrow-right3 
  content: "\f110"

.spot-icon_arrow-right3:before 
  content: "\f110"


@mixin spot-icon-arrow-right4 
  content: "\f111"

.spot-icon_arrow-right4:before 
  content: "\f111"


@mixin spot-icon-arrow-right5 
  content: "\f112"

.spot-icon_arrow-right5:before 
  content: "\f112"


@mixin spot-icon-arrow-right6 
  content: "\f113"

.spot-icon_arrow-right6:before 
  content: "\f113"


@mixin spot-icon-arrow-right7 
  content: "\f114"

.spot-icon_arrow-right7:before 
  content: "\f114"


@mixin spot-icon-arrow-thin 
  content: "\f115"

.spot-icon_arrow-thin:before 
  content: "\f115"


@mixin spot-icon-arrow-up1 
  content: "\f116"

.spot-icon_arrow-up1:before 
  content: "\f116"


@mixin spot-icon-arrow-up2 
  content: "\f117"

.spot-icon_arrow-up2:before 
  content: "\f117"


@mixin spot-icon-assigned-to-me 
  content: "\f118"

.spot-icon_assigned-to-me:before 
  content: "\f118"


@mixin spot-icon-assigned 
  content: "\f119"

.spot-icon_assigned:before 
  content: "\f119"


@mixin spot-icon-attachment 
  content: "\f11a"

.spot-icon_attachment:before 
  content: "\f11a"


@mixin spot-icon-attention 
  content: "\f11b"

.spot-icon_attention:before 
  content: "\f11b"


@mixin spot-icon-back-up 
  content: "\f11c"

.spot-icon_back-up:before 
  content: "\f11c"


@mixin spot-icon-backlogs 
  content: "\f11d"

.spot-icon_backlogs:before 
  content: "\f11d"


@mixin spot-icon-bcf 
  content: "\f11e"

.spot-icon_bcf:before 
  content: "\f11e"


@mixin spot-icon-bell 
  content: "\f11f"

.spot-icon_bell:before 
  content: "\f11f"


@mixin spot-icon-billing-information 
  content: "\f120"

.spot-icon_billing-information:before 
  content: "\f120"


@mixin spot-icon-boards 
  content: "\f121"

.spot-icon_boards:before 
  content: "\f121"


@mixin spot-icon-bold 
  content: "\f122"

.spot-icon_bold:before 
  content: "\f122"


@mixin spot-icon-budget 
  content: "\f123"

.spot-icon_budget:before 
  content: "\f123"


@mixin spot-icon-bug 
  content: "\f124"

.spot-icon_bug:before 
  content: "\f124"


@mixin spot-icon-calendar 
  content: "\f125"

.spot-icon_calendar:before 
  content: "\f125"


@mixin spot-icon-calendar2 
  content: "\f126"

.spot-icon_calendar2:before 
  content: "\f126"


@mixin spot-icon-camera 
  content: "\f127"

.spot-icon_camera:before 
  content: "\f127"


@mixin spot-icon-cancel 
  content: "\f128"

.spot-icon_cancel:before 
  content: "\f128"


@mixin spot-icon-cart 
  content: "\f129"

.spot-icon_cart:before 
  content: "\f129"


@mixin spot-icon-changeset-down 
  content: "\f12a"

.spot-icon_changeset-down:before 
  content: "\f12a"


@mixin spot-icon-changeset-up 
  content: "\f12b"

.spot-icon_changeset-up:before 
  content: "\f12b"


@mixin spot-icon-changeset 
  content: "\f12c"

.spot-icon_changeset:before 
  content: "\f12c"


@mixin spot-icon-chart1 
  content: "\f12d"

.spot-icon_chart1:before 
  content: "\f12d"


@mixin spot-icon-chart2 
  content: "\f12e"

.spot-icon_chart2:before 
  content: "\f12e"


@mixin spot-icon-chart3 
  content: "\f12f"

.spot-icon_chart3:before 
  content: "\f12f"


@mixin spot-icon-checkmark 
  content: "\f130"

.spot-icon_checkmark:before 
  content: "\f130"


@mixin spot-icon-close 
  content: "\f131"

.spot-icon_close:before 
  content: "\f131"


@mixin spot-icon-code-tag 
  content: "\f132"

.spot-icon_code-tag:before 
  content: "\f132"


@mixin spot-icon-color-text 
  content: "\f133"

.spot-icon_color-text:before 
  content: "\f133"


@mixin spot-icon-color-underline 
  content: "\f134"

.spot-icon_color-underline:before 
  content: "\f134"


@mixin spot-icon-column-left 
  content: "\f135"

.spot-icon_column-left:before 
  content: "\f135"


@mixin spot-icon-column-right 
  content: "\f136"

.spot-icon_column-right:before 
  content: "\f136"


@mixin spot-icon-columns 
  content: "\f137"

.spot-icon_columns:before 
  content: "\f137"


@mixin spot-icon-compare2 
  content: "\f138"

.spot-icon_compare2:before 
  content: "\f138"


@mixin spot-icon-concept 
  content: "\f139"

.spot-icon_concept:before 
  content: "\f139"


@mixin spot-icon-console-light 
  content: "\f13a"

.spot-icon_console-light:before 
  content: "\f13a"


@mixin spot-icon-console 
  content: "\f13b"

.spot-icon_console:before 
  content: "\f13b"


@mixin spot-icon-contacts 
  content: "\f13c"

.spot-icon_contacts:before 
  content: "\f13c"


@mixin spot-icon-copy 
  content: "\f13d"

.spot-icon_copy:before 
  content: "\f13d"


@mixin spot-icon-cost-reports 
  content: "\f13e"

.spot-icon_cost-reports:before 
  content: "\f13e"


@mixin spot-icon-cost-types 
  content: "\f13f"

.spot-icon_cost-types:before 
  content: "\f13f"


@mixin spot-icon-cursor 
  content: "\f140"

.spot-icon_cursor:before 
  content: "\f140"


@mixin spot-icon-custom-development 
  content: "\f141"

.spot-icon_custom-development:before 
  content: "\f141"


@mixin spot-icon-custom-fields 
  content: "\f142"

.spot-icon_custom-fields:before 
  content: "\f142"


@mixin spot-icon-cut 
  content: "\f143"

.spot-icon_cut:before 
  content: "\f143"


@mixin spot-icon-delete-folder 
  content: "\f144"

.spot-icon_delete-folder:before 
  content: "\f144"


@mixin spot-icon-delete 
  content: "\f145"

.spot-icon_delete:before 
  content: "\f145"


@mixin spot-icon-dependency 
  content: "\f146"

.spot-icon_dependency:before 
  content: "\f146"


@mixin spot-icon-design 
  content: "\f147"

.spot-icon_design:before 
  content: "\f147"


@mixin spot-icon-double-arrow-left 
  content: "\f148"

.spot-icon_double-arrow-left:before 
  content: "\f148"


@mixin spot-icon-double-arrow-right 
  content: "\f149"

.spot-icon_double-arrow-right:before 
  content: "\f149"


@mixin spot-icon-download 
  content: "\f14a"

.spot-icon_download:before 
  content: "\f14a"


@mixin spot-icon-drag-handle 
  content: "\f14b"

.spot-icon_drag-handle:before 
  content: "\f14b"


@mixin spot-icon-duplicate 
  content: "\f14c"

.spot-icon_duplicate:before 
  content: "\f14c"


@mixin spot-icon-edit 
  content: "\f14d"

.spot-icon_edit:before 
  content: "\f14d"


@mixin spot-icon-email-alert 
  content: "\f14e"

.spot-icon_email-alert:before 
  content: "\f14e"


@mixin spot-icon-enterprise 
  content: "\f14f"

.spot-icon_enterprise:before 
  content: "\f14f"


@mixin spot-icon-enumerations 
  content: "\f150"

.spot-icon_enumerations:before 
  content: "\f150"


@mixin spot-icon-error 
  content: "\f151"

.spot-icon_error:before 
  content: "\f151"


@mixin spot-icon-export-atom 
  content: "\f152"

.spot-icon_export-atom:before 
  content: "\f152"


@mixin spot-icon-export-bcf 
  content: "\f153"

.spot-icon_export-bcf:before 
  content: "\f153"


@mixin spot-icon-export-csv 
  content: "\f154"

.spot-icon_export-csv:before 
  content: "\f154"


@mixin spot-icon-export-pdf-descr 
  content: "\f155"

.spot-icon_export-pdf-descr:before 
  content: "\f155"


@mixin spot-icon-export-pdf-with-descriptions 
  content: "\f156"

.spot-icon_export-pdf-with-descriptions:before 
  content: "\f156"


@mixin spot-icon-export-pdf 
  content: "\f157"

.spot-icon_export-pdf:before 
  content: "\f157"


@mixin spot-icon-export-xls-descr 
  content: "\f158"

.spot-icon_export-xls-descr:before 
  content: "\f158"


@mixin spot-icon-export-xls-with-descriptions 
  content: "\f159"

.spot-icon_export-xls-with-descriptions:before 
  content: "\f159"


@mixin spot-icon-export-xls-with-relations 
  content: "\f15a"

.spot-icon_export-xls-with-relations:before 
  content: "\f15a"


@mixin spot-icon-export-xls 
  content: "\f15b"

.spot-icon_export-xls:before 
  content: "\f15b"


@mixin spot-icon-export 
  content: "\f15c"

.spot-icon_export:before 
  content: "\f15c"


@mixin spot-icon-external-link 
  content: "\f15d"

.spot-icon_external-link:before 
  content: "\f15d"


@mixin spot-icon-faq 
  content: "\f15e"

.spot-icon_faq:before 
  content: "\f15e"


@mixin spot-icon-filter 
  content: "\f15f"

.spot-icon_filter:before 
  content: "\f15f"


@mixin spot-icon-flag 
  content: "\f160"

.spot-icon_flag:before 
  content: "\f160"


@mixin spot-icon-folder-add 
  content: "\f161"

.spot-icon_folder-add:before 
  content: "\f161"


@mixin spot-icon-folder-locked 
  content: "\f162"

.spot-icon_folder-locked:before 
  content: "\f162"


@mixin spot-icon-folder-open 
  content: "\f163"

.spot-icon_folder-open:before 
  content: "\f163"


@mixin spot-icon-folder-remove 
  content: "\f164"

.spot-icon_folder-remove:before 
  content: "\f164"


@mixin spot-icon-folder 
  content: "\f165"

.spot-icon_folder:before 
  content: "\f165"


@mixin spot-icon-forums 
  content: "\f166"

.spot-icon_forums:before 
  content: "\f166"


@mixin spot-icon-from-fullscreen 
  content: "\f167"

.spot-icon_from-fullscreen:before 
  content: "\f167"


@mixin spot-icon-getting-started 
  content: "\f168"

.spot-icon_getting-started:before 
  content: "\f168"


@mixin spot-icon-glossar 
  content: "\f169"

.spot-icon_glossar:before 
  content: "\f169"


@mixin spot-icon-google-plus 
  content: "\f16a"

.spot-icon_google-plus:before 
  content: "\f16a"


@mixin spot-icon-group-by 
  content: "\f16b"

.spot-icon_group-by:before 
  content: "\f16b"


@mixin spot-icon-group 
  content: "\f16c"

.spot-icon_group:before 
  content: "\f16c"


@mixin spot-icon-hamburger 
  content: "\f16d"

.spot-icon_hamburger:before 
  content: "\f16d"


@mixin spot-icon-headline1 
  content: "\f16e"

.spot-icon_headline1:before 
  content: "\f16e"


@mixin spot-icon-headline2 
  content: "\f16f"

.spot-icon_headline2:before 
  content: "\f16f"


@mixin spot-icon-headline3 
  content: "\f170"

.spot-icon_headline3:before 
  content: "\f170"


@mixin spot-icon-headset 
  content: "\f171"

.spot-icon_headset:before 
  content: "\f171"


@mixin spot-icon-help 
  content: "\f172"

.spot-icon_help:before 
  content: "\f172"


@mixin spot-icon-help1 
  content: "\f173"

.spot-icon_help1:before 
  content: "\f173"


@mixin spot-icon-help2 
  content: "\f174"

.spot-icon_help2:before 
  content: "\f174"


@mixin spot-icon-hierarchy 
  content: "\f175"

.spot-icon_hierarchy:before 
  content: "\f175"


@mixin spot-icon-home 
  content: "\f176"

.spot-icon_home:before 
  content: "\f176"


@mixin spot-icon-hosting 
  content: "\f177"

.spot-icon_hosting:before 
  content: "\f177"


@mixin spot-icon-ifc 
  content: "\f178"

.spot-icon_ifc:before 
  content: "\f178"


@mixin spot-icon-image1 
  content: "\f179"

.spot-icon_image1:before 
  content: "\f179"


@mixin spot-icon-image2 
  content: "\f17a"

.spot-icon_image2:before 
  content: "\f17a"


@mixin spot-icon-import 
  content: "\f17b"

.spot-icon_import:before 
  content: "\f17b"


@mixin spot-icon-inbox 
  content: "\f17c"

.spot-icon_inbox:before 
  content: "\f17c"


@mixin spot-icon-info1 
  content: "\f17d"

.spot-icon_info1:before 
  content: "\f17d"


@mixin spot-icon-info2 
  content: "\f17e"

.spot-icon_info2:before 
  content: "\f17e"


@mixin spot-icon-input-disabled 
  content: "\f17f"

.spot-icon_input-disabled:before 
  content: "\f17f"


@mixin spot-icon-installation-services 
  content: "\f180"

.spot-icon_installation-services:before 
  content: "\f180"


@mixin spot-icon-italic 
  content: "\f181"

.spot-icon_italic:before 
  content: "\f181"


@mixin spot-icon-key 
  content: "\f182"

.spot-icon_key:before 
  content: "\f182"


@mixin spot-icon-link 
  content: "\f183"

.spot-icon_link:before 
  content: "\f183"


@mixin spot-icon-loading1 
  content: "\f184"

.spot-icon_loading1:before 
  content: "\f184"


@mixin spot-icon-loading2 
  content: "\f185"

.spot-icon_loading2:before 
  content: "\f185"


@mixin spot-icon-location 
  content: "\f186"

.spot-icon_location:before 
  content: "\f186"


@mixin spot-icon-locked 
  content: "\f187"

.spot-icon_locked:before 
  content: "\f187"


@mixin spot-icon-logout 
  content: "\f188"

.spot-icon_logout:before 
  content: "\f188"


@mixin spot-icon-mail1 
  content: "\f189"

.spot-icon_mail1:before 
  content: "\f189"


@mixin spot-icon-mail2 
  content: "\f18a"

.spot-icon_mail2:before 
  content: "\f18a"


@mixin spot-icon-maintenance-support 
  content: "\f18b"

.spot-icon_maintenance-support:before 
  content: "\f18b"


@mixin spot-icon-mark-all-read 
  content: "\f18c"

.spot-icon_mark-all-read:before 
  content: "\f18c"


@mixin spot-icon-mark-read 
  content: "\f18d"

.spot-icon_mark-read:before 
  content: "\f18d"


@mixin spot-icon-medal 
  content: "\f18e"

.spot-icon_medal:before 
  content: "\f18e"


@mixin spot-icon-meetings 
  content: "\f18f"

.spot-icon_meetings:before 
  content: "\f18f"


@mixin spot-icon-mention 
  content: "\f190"

.spot-icon_mention:before 
  content: "\f190"


@mixin spot-icon-menu 
  content: "\f191"

.spot-icon_menu:before 
  content: "\f191"


@mixin spot-icon-merge-branch 
  content: "\f192"

.spot-icon_merge-branch:before 
  content: "\f192"


@mixin spot-icon-microphone 
  content: "\f193"

.spot-icon_microphone:before 
  content: "\f193"


@mixin spot-icon-milestone 
  content: "\f194"

.spot-icon_milestone:before 
  content: "\f194"


@mixin spot-icon-minus1 
  content: "\f195"

.spot-icon_minus1:before 
  content: "\f195"


@mixin spot-icon-minus2 
  content: "\f196"

.spot-icon_minus2:before 
  content: "\f196"


@mixin spot-icon-mobile 
  content: "\f197"

.spot-icon_mobile:before 
  content: "\f197"


@mixin spot-icon-modules 
  content: "\f198"

.spot-icon_modules:before 
  content: "\f198"


@mixin spot-icon-more 
  content: "\f199"

.spot-icon_more:before 
  content: "\f199"


@mixin spot-icon-move 
  content: "\f19a"

.spot-icon_move:before 
  content: "\f19a"


@mixin spot-icon-movie 
  content: "\f19b"

.spot-icon_movie:before 
  content: "\f19b"


@mixin spot-icon-music 
  content: "\f19c"

.spot-icon_music:before 
  content: "\f19c"


@mixin spot-icon-new-planning-element 
  content: "\f19d"

.spot-icon_new-planning-element:before 
  content: "\f19d"


@mixin spot-icon-news 
  content: "\f19e"

.spot-icon_news:before 
  content: "\f19e"


@mixin spot-icon-no-hierarchy 
  content: "\f19f"

.spot-icon_no-hierarchy:before 
  content: "\f19f"


@mixin spot-icon-no-zen-mode 
  content: "\f1a0"

.spot-icon_no-zen-mode:before 
  content: "\f1a0"


@mixin spot-icon-not-supported 
  content: "\f1a1"

.spot-icon_not-supported:before 
  content: "\f1a1"


@mixin spot-icon-notes 
  content: "\f1a2"

.spot-icon_notes:before 
  content: "\f1a2"


@mixin spot-icon-openid 
  content: "\f1a3"

.spot-icon_openid:before 
  content: "\f1a3"


@mixin spot-icon-openproject 
  content: "\f1a4"

.spot-icon_openproject:before 
  content: "\f1a4"


@mixin spot-icon-ordered-list 
  content: "\f1a5"

.spot-icon_ordered-list:before 
  content: "\f1a5"


@mixin spot-icon-outline 
  content: "\f1a6"

.spot-icon_outline:before 
  content: "\f1a6"


@mixin spot-icon-paragraph-left 
  content: "\f1a7"

.spot-icon_paragraph-left:before 
  content: "\f1a7"


@mixin spot-icon-paragraph-right 
  content: "\f1a8"

.spot-icon_paragraph-right:before 
  content: "\f1a8"


@mixin spot-icon-paragraph 
  content: "\f1a9"

.spot-icon_paragraph:before 
  content: "\f1a9"


@mixin spot-icon-payment-history 
  content: "\f1aa"

.spot-icon_payment-history:before 
  content: "\f1aa"


@mixin spot-icon-phone 
  content: "\f1ab"

.spot-icon_phone:before 
  content: "\f1ab"


@mixin spot-icon-pin 
  content: "\f1ac"

.spot-icon_pin:before 
  content: "\f1ac"


@mixin spot-icon-play 
  content: "\f1ad"

.spot-icon_play:before 
  content: "\f1ad"


@mixin spot-icon-plugins 
  content: "\f1ae"

.spot-icon_plugins:before 
  content: "\f1ae"


@mixin spot-icon-plus 
  content: "\f1af"

.spot-icon_plus:before 
  content: "\f1af"


@mixin spot-icon-pre 
  content: "\f1b0"

.spot-icon_pre:before 
  content: "\f1b0"


@mixin spot-icon-presentation 
  content: "\f1b1"

.spot-icon_presentation:before 
  content: "\f1b1"


@mixin spot-icon-preview 
  content: "\f1b2"

.spot-icon_preview:before 
  content: "\f1b2"


@mixin spot-icon-print 
  content: "\f1b3"

.spot-icon_print:before 
  content: "\f1b3"


@mixin spot-icon-priority 
  content: "\f1b4"

.spot-icon_priority:before 
  content: "\f1b4"


@mixin spot-icon-project-types 
  content: "\f1b5"

.spot-icon_project-types:before 
  content: "\f1b5"


@mixin spot-icon-projects 
  content: "\f1b6"

.spot-icon_projects:before 
  content: "\f1b6"


@mixin spot-icon-publish 
  content: "\f1b7"

.spot-icon_publish:before 
  content: "\f1b7"


@mixin spot-icon-pulldown-up 
  content: "\f1b8"

.spot-icon_pulldown-up:before 
  content: "\f1b8"


@mixin spot-icon-pulldown 
  content: "\f1b9"

.spot-icon_pulldown:before 
  content: "\f1b9"


@mixin spot-icon-quote 
  content: "\f1ba"

.spot-icon_quote:before 
  content: "\f1ba"


@mixin spot-icon-quote2 
  content: "\f1bb"

.spot-icon_quote2:before 
  content: "\f1bb"


@mixin spot-icon-redo 
  content: "\f1bc"

.spot-icon_redo:before 
  content: "\f1bc"


@mixin spot-icon-relation-follows 
  content: "\f1bd"

.spot-icon_relation-follows:before 
  content: "\f1bd"


@mixin spot-icon-relation-new-child 
  content: "\f1be"

.spot-icon_relation-new-child:before 
  content: "\f1be"


@mixin spot-icon-relation-precedes 
  content: "\f1bf"

.spot-icon_relation-precedes:before 
  content: "\f1bf"


@mixin spot-icon-relations 
  content: "\f1c0"

.spot-icon_relations:before 
  content: "\f1c0"


@mixin spot-icon-reload 
  content: "\f1c1"

.spot-icon_reload:before 
  content: "\f1c1"


@mixin spot-icon-reminder 
  content: "\f1c2"

.spot-icon_reminder:before 
  content: "\f1c2"


@mixin spot-icon-remove 
  content: "\f1c3"

.spot-icon_remove:before 
  content: "\f1c3"


@mixin spot-icon-rename 
  content: "\f1c4"

.spot-icon_rename:before 
  content: "\f1c4"


@mixin spot-icon-reported-by-me 
  content: "\f1c5"

.spot-icon_reported-by-me:before 
  content: "\f1c5"


@mixin spot-icon-resizer-bottom-right 
  content: "\f1c6"

.spot-icon_resizer-bottom-right:before 
  content: "\f1c6"


@mixin spot-icon-resizer-vertical-lines 
  content: "\f1c7"

.spot-icon_resizer-vertical-lines:before 
  content: "\f1c7"


@mixin spot-icon-roadmap 
  content: "\f1c8"

.spot-icon_roadmap:before 
  content: "\f1c8"


@mixin spot-icon-rss 
  content: "\f1c9"

.spot-icon_rss:before 
  content: "\f1c9"


@mixin spot-icon-rubber 
  content: "\f1ca"

.spot-icon_rubber:before 
  content: "\f1ca"


@mixin spot-icon-save 
  content: "\f1cb"

.spot-icon_save:before 
  content: "\f1cb"


@mixin spot-icon-search 
  content: "\f1cc"

.spot-icon_search:before 
  content: "\f1cc"


@mixin spot-icon-send-mail 
  content: "\f1cd"

.spot-icon_send-mail:before 
  content: "\f1cd"


@mixin spot-icon-server-key 
  content: "\f1ce"

.spot-icon_server-key:before 
  content: "\f1ce"


@mixin spot-icon-settings 
  content: "\f1cf"

.spot-icon_settings:before 
  content: "\f1cf"


@mixin spot-icon-settings2 
  content: "\f1d0"

.spot-icon_settings2:before 
  content: "\f1d0"


@mixin spot-icon-settings3 
  content: "\f1d1"

.spot-icon_settings3:before 
  content: "\f1d1"


@mixin spot-icon-settings4 
  content: "\f1d2"

.spot-icon_settings4:before 
  content: "\f1d2"


@mixin spot-icon-shortcuts 
  content: "\f1d3"

.spot-icon_shortcuts:before 
  content: "\f1d3"


@mixin spot-icon-show-all-projects 
  content: "\f1d4"

.spot-icon_show-all-projects:before 
  content: "\f1d4"


@mixin spot-icon-show-more-horizontal 
  content: "\f1d5"

.spot-icon_show-more-horizontal:before 
  content: "\f1d5"


@mixin spot-icon-show-more 
  content: "\f1d6"

.spot-icon_show-more:before 
  content: "\f1d6"


@mixin spot-icon-slack 
  content: "\f1d7"

.spot-icon_slack:before 
  content: "\f1d7"


@mixin spot-icon-sort-ascending 
  content: "\f1d8"

.spot-icon_sort-ascending:before 
  content: "\f1d8"


@mixin spot-icon-sort-by 
  content: "\f1d9"

.spot-icon_sort-by:before 
  content: "\f1d9"


@mixin spot-icon-sort-descending 
  content: "\f1da"

.spot-icon_sort-descending:before 
  content: "\f1da"


@mixin spot-icon-sort-down 
  content: "\f1db"

.spot-icon_sort-down:before 
  content: "\f1db"


@mixin spot-icon-sort-up 
  content: "\f1dc"

.spot-icon_sort-up:before 
  content: "\f1dc"


@mixin spot-icon-square 
  content: "\f1dd"

.spot-icon_square:before 
  content: "\f1dd"


@mixin spot-icon-star 
  content: "\f1de"

.spot-icon_star:before 
  content: "\f1de"


@mixin spot-icon-status-reporting 
  content: "\f1df"

.spot-icon_status-reporting:before 
  content: "\f1df"


@mixin spot-icon-status 
  content: "\f1e0"

.spot-icon_status:before 
  content: "\f1e0"


@mixin spot-icon-strike-through 
  content: "\f1e1"

.spot-icon_strike-through:before 
  content: "\f1e1"


@mixin spot-icon-team-planner 
  content: "\f1e2"

.spot-icon_team-planner:before 
  content: "\f1e2"


@mixin spot-icon-text 
  content: "\f1e3"

.spot-icon_text:before 
  content: "\f1e3"


@mixin spot-icon-ticket-checked 
  content: "\f1e4"

.spot-icon_ticket-checked:before 
  content: "\f1e4"


@mixin spot-icon-ticket-down 
  content: "\f1e5"

.spot-icon_ticket-down:before 
  content: "\f1e5"


@mixin spot-icon-ticket-edit 
  content: "\f1e6"

.spot-icon_ticket-edit:before 
  content: "\f1e6"


@mixin spot-icon-ticket-minus 
  content: "\f1e7"

.spot-icon_ticket-minus:before 
  content: "\f1e7"


@mixin spot-icon-ticket-note 
  content: "\f1e8"

.spot-icon_ticket-note:before 
  content: "\f1e8"


@mixin spot-icon-ticket 
  content: "\f1e9"

.spot-icon_ticket:before 
  content: "\f1e9"


@mixin spot-icon-time 
  content: "\f1ea"

.spot-icon_time:before 
  content: "\f1ea"


@mixin spot-icon-to-fullscreen 
  content: "\f1eb"

.spot-icon_to-fullscreen:before 
  content: "\f1eb"


@mixin spot-icon-training-consulting 
  content: "\f1ec"

.spot-icon_training-consulting:before 
  content: "\f1ec"


@mixin spot-icon-two-factor-authentication 
  content: "\f1ed"

.spot-icon_two-factor-authentication:before 
  content: "\f1ed"


@mixin spot-icon-types 
  content: "\f1ee"

.spot-icon_types:before 
  content: "\f1ee"


@mixin spot-icon-underline 
  content: "\f1ef"

.spot-icon_underline:before 
  content: "\f1ef"


@mixin spot-icon-undo 
  content: "\f1f0"

.spot-icon_undo:before 
  content: "\f1f0"


@mixin spot-icon-unit 
  content: "\f1f1"

.spot-icon_unit:before 
  content: "\f1f1"


@mixin spot-icon-unlocked 
  content: "\f1f2"

.spot-icon_unlocked:before 
  content: "\f1f2"


@mixin spot-icon-unordered-list 
  content: "\f1f3"

.spot-icon_unordered-list:before 
  content: "\f1f3"


@mixin spot-icon-unwatched 
  content: "\f1f4"

.spot-icon_unwatched:before 
  content: "\f1f4"


@mixin spot-icon-upload 
  content: "\f1f5"

.spot-icon_upload:before 
  content: "\f1f5"


@mixin spot-icon-user-minus 
  content: "\f1f6"

.spot-icon_user-minus:before 
  content: "\f1f6"


@mixin spot-icon-user-plus 
  content: "\f1f7"

.spot-icon_user-plus:before 
  content: "\f1f7"


@mixin spot-icon-user 
  content: "\f1f8"

.spot-icon_user:before 
  content: "\f1f8"


@mixin spot-icon-view-card 
  content: "\f1f9"

.spot-icon_view-card:before 
  content: "\f1f9"


@mixin spot-icon-view-fullscreen 
  content: "\f1fa"

.spot-icon_view-fullscreen:before 
  content: "\f1fa"


@mixin spot-icon-view-list 
  content: "\f1fb"

.spot-icon_view-list:before 
  content: "\f1fb"


@mixin spot-icon-view-model 
  content: "\f1fc"

.spot-icon_view-model:before 
  content: "\f1fc"


@mixin spot-icon-view-split-viewer-table 
  content: "\f1fd"

.spot-icon_view-split-viewer-table:before 
  content: "\f1fd"


@mixin spot-icon-view-split 
  content: "\f1fe"

.spot-icon_view-split:before 
  content: "\f1fe"


@mixin spot-icon-view-split2 
  content: "\f1ff"

.spot-icon_view-split2:before 
  content: "\f1ff"


@mixin spot-icon-view-timeline 
  content: "\f200"

.spot-icon_view-timeline:before 
  content: "\f200"


@mixin spot-icon-warning 
  content: "\f201"

.spot-icon_warning:before 
  content: "\f201"


@mixin spot-icon-watched 
  content: "\f202"

.spot-icon_watched:before 
  content: "\f202"


@mixin spot-icon-watching 
  content: "\f203"

.spot-icon_watching:before 
  content: "\f203"


@mixin spot-icon-wiki-edit 
  content: "\f204"

.spot-icon_wiki-edit:before 
  content: "\f204"


@mixin spot-icon-wiki 
  content: "\f205"

.spot-icon_wiki:before 
  content: "\f205"


@mixin spot-icon-wiki2 
  content: "\f206"

.spot-icon_wiki2:before 
  content: "\f206"


@mixin spot-icon-work-packages 
  content: "\f207"

.spot-icon_work-packages:before 
  content: "\f207"


@mixin spot-icon-workflow 
  content: "\f208"

.spot-icon_workflow:before 
  content: "\f208"


@mixin spot-icon-yes 
  content: "\f209"

.spot-icon_yes:before 
  content: "\f209"


@mixin spot-icon-zen-mode 
  content: "\f20a"

.spot-icon_zen-mode:before 
  content: "\f20a"


@mixin spot-icon-zoom-auto 
  content: "\f20b"

.spot-icon_zoom-auto:before 
  content: "\f20b"


@mixin spot-icon-zoom-in 
  content: "\f20c"

.spot-icon_zoom-in:before 
  content: "\f20c"


@mixin spot-icon-zoom-out 
  content: "\f20d"

.spot-icon_zoom-out:before 
  content: "\f20d"

