//-- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2022 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

@include breakpoint(680px down)
  .main-menu
    position: fixed
    z-index: 12
    border-bottom: 1px solid var(--main-menu-border-color)
    border: none
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15)
    min-width: 75vw

  #main-menu-toggle 
    .icon-hamburger
      display: none
    .icon-close
      display: block

  .hidden-navigation
    .main-menu
      display: none
    #main-menu-toggle 
      .icon-hamburger
        display: block
      .icon-close
        display: none


  .main-item-wrapper a
    width: 100%

  .main-menu--resizer
    display: none

  .content-overlay
    // Background color transition simulates a fade-in effect
    background: #fff
    transition: background .3s

  #main:not(.nosidebar)
    .content-overlay
      display: block

  #wrapper:not(.hidden-navigation)
    .content-overlay
      position: fixed
      height: 100%
      background: #000
      top: 55px
      left: 0
      bottom: 0
      right: 0
      opacity: .4
      z-index: 11
