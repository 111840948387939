//-- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2022 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

.user-comment
  display: block
  margin: 12px 0 0 0
  padding: 0 25px 0 0

  li, p
    word-wrap: break-word

  &.wiki
    ul
      padding-left: 18px

  > .message:first-child
    display: block
    margin-bottom: 1rem

    p:last-child
      margin-bottom: 0

    ul
      margin-bottom: 1rem

.comments-number
  position: absolute
  right: 0
  top: 10px
  display: grid
  align-items: center
  grid-template: auto auto / auto auto
  grid-column-gap: 5px

  &--link
    grid-column-end: 3

.comments-icons
  padding: 0
  text-align: right
  display: block
  grid-column: 1 / 3
  i
    padding: 5px 0 0 5px
    display: inline-block

.comment ul
  list-style-type: disc !important

  li
    padding: 0 !important
    margin: 0 !important

h4.comment
  margin-bottom: 8px
  margin-top: 18px

.work-package-details-activities-list
  list-style-type: none
  margin: 20px 0 0 0

// Position first date with comment toggler
.activity-date.-with-toggler
  display: flex
  // Align span and button in center
  align-items: center
  // Let button stretch to the far right
  align-content: stretch

  .activity-date--label
    flex: 1
  .activity-comments--toggler
    margin: 0

.work-package-details-activities-activity:not(:last-child)
  margin-bottom: 10px

.work-package-details-activities-activity-contents
  position: relative
  padding: 10px 0

  textarea
    width: 100%
    resize: none
    margin-bottom: 0

  .avatar
    float: left
    margin: -2px 10px 0 0

  .avatar-mini
    float: left
    margin: -3px 7px 0 0

  .comments-icons
    a:hover
      text-decoration: none
  .action-icon
    color: var(--content-icon-link-color)
    &::before
      padding: 0 0 0 0.3em

ul.work-package-details-activities-messages
  li
    color: var(--gray-dark)
    margin-bottom: 0.1rem

    &:last-child
      margin-bottom: 0

.activity-thumbnail
  height: 100px
  display: block
  margin-top: 17px
