@use "sass:list"

.spot-list
  display: flex
  flex-direction: column
  list-style: none
  margin: 0

  &--item
    display: flex
    flex-direction: column

    &-action
      @include spot-body-big

      display: flex
      align-items: center
      justify-content: flex-start
      padding: $spot-spacing-0_5
      background-color: transparent
      margin: 0
      border: 0
      cursor: pointer

      &:hover
        background-color: $spot-color-main-light

      &_disabled
        color: $spot-color-basic-gray-3
        cursor: default

    &-title
      min-height: $spot-spacing-1_5

      &:not(:first-child)
        margin-left: $spot-spacing-0_5
      &:not(:last-child)
        margin-left: $spot-spacing-0_5

  $item-list: ""
  @for $i from 1 through 10
    $item-list: list.append($item-list, ".spot-list--item")

    #{$item-list} .spot-list--item-action
      padding-left: $i * $spot-spacing-1
