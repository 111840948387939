@mixin spot-header-big($weight: bold, $style: normal)
  font-family: Lato, sans-serif
  font-size: 2rem
  font-weight: $weight
  font-style: $style
  line-height: 2.5em

@mixin spot-header-small($weight: bold, $style: normal)
  font-family: Lato, sans-serif
  font-size: 1.5rem
  font-weight: $weight
  font-style: $style
  line-height: 2rem

@mixin spot-subheader-big($weight: bold, $style: normal)
  font-family: Lato, sans-serif
  font-size: 1.25rem
  font-weight: $weight
  font-style: $style
  line-height: 1.75rem

@mixin spot-subheader-small($weight: bold, $style: normal)
  font-family: Lato, sans-serif
  font-size: 1.125rem
  font-weight: $weight
  font-style: $style
  line-height: 1.5rem

@mixin spot-body-big($weight: normal, $style: normal)
  font-family: Lato, sans-serif
  font-size: 1rem
  font-weight: $weight
  font-style: $style
  line-height: 1.5rem

@mixin spot-body-small($weight: normal, $style: normal)
  font-family: Lato, sans-serif
  font-size: 0.875rem
  font-weight: $weight
  font-style: $style
  line-height: 1.25rem

@mixin spot-caption($weight: normal, $style: normal)
  font-family: Lato, sans-serif
  font-size: 0.75rem
  font-weight: $weight
  font-style: $style
  line-height: 1rem
