.ng-dropdown-footer
  padding: 0 !important

.ng-select .ng-select-container
  border-radius: 2px

.ng-select .ng-value-container
  min-height: 2rem

.ng-placeholder
  line-height: 2rem
